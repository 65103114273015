//
// flickity.js
//

import Flickity from "flickity";

// Flickity defaults
Flickity.defaults.pageDots = false;
Flickity.defaults.prevNextButtons = false;
Flickity.defaults.imagesLoaded = true;
Flickity.defaults.initialIndex = 0;
Flickity.defaults.cellAlign = "left";
// Must be false. Check out the following links:
// https://github.com/metafizzy/flickity/issues/341
// https://github.com/metafizzy/flickity/issues/660
Flickity.defaults.wrapAround = false;
Flickity.defaults.contain = true;

// Toggles
const toggles = document.querySelectorAll('[data-toggle="flickity"]');

toggles.forEach((toggle) => {
  toggle.addEventListener("click", () => {
    const slide = toggle.getAttribute("data-slide");
    const target = toggle.getAttribute("data-target");
    const targetEl = document.querySelector(target);
    const slider = Flickity.data(targetEl);
    slider.select(slide, null, true);
  });
});

window.Flickity = Flickity;
